:root{
  --primary-color: #2e946f;
  /* #f87171 */
}

.cairo-font{
  font-family: "Cairo";
}
.bg-primary-color{
  background-color: var(--primary-color);
}
.border-primary-color {
  border-color: var(--primary-color);
}
.primary-color {
  color: var(--primary-color);
}

h1 {
  font-size: 2em;
  font-weight: bolder;
}
h2 {
  font-size: 1.5em;
  font-weight: bolder;
}
h3 {
  font-size: 1.17em;
  font-weight: bolder;
}
h4 {
  font-size: 1em;
  font-weight: bolder;
}
h5 {
  font-size: 0.83em;
  font-weight: bolder;
}
h6 {
  font-size: 0.67em;
  font-weight: bolder;
}
.header-info {
  background-image: url("./img/header-bg.svg");
  background-size: 30%;
  background-repeat: no-repeat;
}
nav a {
position: relative;
padding: 10px 15px;
font-size: 14px;
width: 100%;
}
nav a:hover{
  scale: 1.1;
  transition: 0.25s;
}
nav a.active {
  background-color: #2e946f38;
  transition-duration: 0.25s;
}

.home-card {
  box-shadow: rgba(158, 154, 154, 0.4) 0px 10px 30px;
}
.home-card .actions,
.files-card .actions {
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}
.home-card:hover .actions,
.files-card:hover .actions {
  visibility: visible;
  opacity: 1;
}
.normal-link {
  padding: 0;
  box-shadow: none;
  color: var(--primary-color);
  border: none;
}
.normal-link:hover{ border: none }
.table-bordered,
.table-bordered th,
.table-bordered td {
  border: 1px solid #000;
  /* Adjust the color and style as needed */
  border-collapse: collapse;
  padding: 15px;
  margin: 20px 0;
}
.login_bg{
  background: #2e946f18;
}
.transparent_bg{
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='24' viewBox='0 0 88 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='autumn' fill='%232e946f' fill-opacity='0.06'%3E%3Cpath d='M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.presentation_bg {
  background: url(img/presentation_bg.svg) no-repeat;
  background-position: center 100px;
  background-size: contain;
}
@media (max-width: 1100px) {
  nav a.active::before,
  nav a.active::after{
    display: none;
  }
  nav a.active,
  nav a:hover {
    margin-bottom: 0;
  }
}