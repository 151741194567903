/* TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@tailwind base;

@layer base {
  button, :not(nav) > a {
    @apply border border-white hover:border-green-600 hover:bg-white hover:text-black shadow-md px-5 py-1
  }
  select:focus,
  textarea:focus,
  input:focus {
    outline-color: #2e946f;
    outline-width: 1px;
    outline-style: dashed
  }

  html {
    @apply text-neutral-800;
  }

  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
  /* Custom scrollbar styles */
  .scrollbar-custom {
    @apply overflow-auto scrollbar-default;
  }

  /* Optional: hiding scrollbar */
  .scrollbar-hide {
    @apply scrollbar-hide;
  }
}

@tailwind components;
@tailwind utilities;